import React, { ReactNode, ReactElement } from "react"
import { SnackbarProvider } from "notistack"

const wrapRootElement = ({ element }: { element: ReactNode }): ReactElement => {
  return (
    <SnackbarProvider maxSnack={3} preventDuplicate>
      {element}
    </SnackbarProvider>
  )
}

export default wrapRootElement
