module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"titleTemplate":"%s | Evo-IS","language":"en","description":"We are a technical company with over ten years experience in software\n        development. We specialise in providing bespoke services for companies of all\n        sizes. We design software that will improve efficiency and productivity.","openGraph":{"type":"website","locale":"en_GB","site_name":"Evo-IS"},"twitter":{"handle":"Evo-IS Limited","site":"@username","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"debug":false,"googleAnalytics":{"trackingId":"UA-72710652-1","autoStart":false,"controlCookieName":"gdpr-analytics-enabled","cookieFlags":"secure;samesite=none"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"evo-is","short_name":"evo-is","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/img/brand/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"65d273760531ca83772bb511a4db6029"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
